* {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  border: 0;

  min-height: 100vh;
  height: 100%;
  background-color: #f5f5f5;
  color: #222;
  font-feature-settings: "pwid"1;
  letter-spacing: 0.02em;
  font-size: 14px;

  font-weight: 400;
  line-height: 1.5;

  font-family: "Open Sans", sans-serif;
}

#app {
  display: flex;
}

.sidebar {
  width: 200px;
  height: 100%;

  background: #eee;
  border: #e8e8e8 solid;
  border-width: 0 1px 0 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 1;

  position: fixed;
}

.sidebar .logo {
  padding: 16px;
  border-bottom: 1px #e8e8e8 solid;
  background: #eee;
}

.sidebar ul {
  margin: 0;
  padding-left: 16px;
}

.sidebar ul li {
  color: #333;
  display: block;
  margin: 8px 0 8px 0;
  font-size: 15px;
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar ul li a {
  font-size: inherit;
  color: #1978c8;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #67b3f1;
  cursor: pointer;
}

.main {
  width: 100%;
  height: 100vh;
  max-width: 1000px;
  padding: 16px 32px;
  margin: 0 auto;
  display: block;

  margin-left: 200px;
}

.code {
  background-color: #ccc;
  border-radius: 8px;
  width: fit-content;
  padding: 10px;
}

.img100 {
  height: 100px;
}

.img200 {
  height: 200px;
}

.img300 {
  height: 300px;
}

.img400 {
  height: 400px;
}

.mar20 {
  margin-left: 20px;
}

.mar40 {
  margin-left: 40px;
}

.mar60 {
  margin-left: 60px;
}

.mar80 {
  margin-left: 80px;
}

.mar100 {
  margin-left: 100px;
}

.mar110 {
  margin-left: 110px;
}

.mar120 {
  margin-left: 120px;
}

/* TAKEN FROM PAGE */

.click-btn {
  display: flex;
  align-items: center;
}

.click-btn a {
  color: #fff;
  background-color: #006aa1;
  padding: 10px 30px;
  border-radius: 4px;
  display: block;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.click-btn a:hover {
  opacity: 0.8;
}

.color-card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: fit-content;
}

.color-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  background-color: #006aa1;
  color: white;
  margin-bottom: 5px;
  border: 1px solid transparent;
}

.color-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 0.8em;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: ". . . . ."". . . . ."". . . . .";
  width: fit-content;
}

.small-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: fit-content;
}

/* SYNALIO LOGO */

#h_logo a svg .st0 {
  fill: #f6b142;
}

#h_logo a svg .st1 {
  fill: #f39504;
}

#h_logo a svg .st2 {
  fill: #00aadd;
}

#h_logo a svg .st3 {
  fill: #0075c4;
}

#h_logo a svg .st4 {
  fill: #eb6d47;
}

#h_logo a svg .st5 {
  fill: #e64c1e;
}

#h_logo a svg .st6 {
  fill: #e53d0d;
}

#h_logo a svg .st7 {
  fill: #ffffff;
}

.answer {
  margin-left: 50px;
}